


import { Link } from "react-router-dom";
import "../styles/homepage.css";
import "../styles/card.css";
import { Image_URL } from "../App";



function CardTeacherProfile({mentor , style , auth}) {

  function formatDate(dateString) {
    try {
      const date = new Date(dateString);
      const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC', numberingSystem: 'arab'};
      const formatter = new Intl.DateTimeFormat('ar-EG', options);
      const formattedDate = formatter.format(date);
      return formattedDate;
    } catch (e) {
      // console.error('Error formatting date', e);
      return '';
    }
  }


   return (
    <>

<div className={`teachers ${style ? "dark-mode" : ""} ps-0 pe-0 pt-1 pb-1 `}>

      <div className="card-teacher mb-5 mb-lg-0">
                      <div className="image">
                        <img
                          src={`${Image_URL}/storage/${mentor.img}`}
                          alt=""
                        />
                        
                      </div>
                      <div className="about">
                        <h2 className="title">
                          {mentor.user.fname + " " + mentor.user.lname}
                        </h2>

                        <p> {mentor.bio}</p>
                      </div>
                     
                      <div className="btn">
                      <Link to={`/auth-courses/mentor/${mentor.id}/year/${auth.level}`}> محتوي المدرس </Link>
                      </div>
                    </div>
                    </div>
                    
    </>
   )
}

export default CardTeacherProfile;