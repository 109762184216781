import { useCallback, useEffect, useState } from "react";
import { BASE_URL, Image_URL } from "../App";
import { useParams } from "react-router";
import "../styles/homepage.css";
import "../styles/card.css";
import "../styles/years.css";
import { Card, Col, Container, Row } from "react-bootstrap";
import CardTeacher from "./CardTeacher";
import CardTeacherSubject from "./CardTeacherSubject";
import { Link } from "react-router-dom";
import CardNot from "./Cardnotlogin";
import axios from "axios";

function SubjectContent({ style, loggedIn, card }) {
  const { id } = useParams();
  const { subjectId, yearId , mentorId} = useParams();
  const [titleSubject , SetTitleSubject] = useState([])
  const [mentors, setMentors] = useState([]);
  const [coursesNotLoginMonthly, setCoursesNotLoginMonthly] = useState([]);
  const [coursesNotLoginWeekly, setCoursesNotLoginWeekly] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const fetchSubject = async () => {
      try {
        const response = await fetch(
          `${BASE_URL}/api/year/${yearId}/subject/${subjectId}`,
          {
            'Content-Type': 'application/json',
            'ngrok-skip-browser-warning': 'any',
            'Authorization': `Bearer ${token}`,
          }
        );
        const data = await response.json();
        setIsLoading(false);
        setMentors(data.data.mentors);
        setCoursesNotLoginMonthly(data.data.monthly_courses);
        setCoursesNotLoginWeekly(data.data.normal_courses);
        SetTitleSubject(data.data.subject);
        console.log(data);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    fetchSubject();
  }, []);

  function formatDate(dateString) {
    try {
      const date = new Date(dateString);
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        timeZone: "UTC",
        numberingSystem: "arab",
      };
      const formatter = new Intl.DateTimeFormat("ar-EG", options);
      const formattedDate = formatter.format(date);
      return formattedDate;
    } catch (e) {
      console.error("Error formatting date", e);
      return "";
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {isLoading ? (
        <div className={`main-loading ${style ? "dark-mode" : ""}`}>
          <div className="loading-course">
            <h2> .... جاري تحميل بيانات المادة</h2>
          </div>
        </div>
      ) : (
        <>
          <div className={`teachers ${style ? "dark-mode" : ""} `}>

            {/* Mentors Of Subject */}

            <Row className="text-center flex-row-reverse">
              <div className="title mb-5">
                <h1>
                  {" "}
                  مدرسين <span>  {titleSubject.name} </span>{" "}
                </h1>
              </div>

              {mentors && mentors.length > 0 ? (
                mentors?.map((mentor) => (
                  <Col xl={3} lg={4} md={4} key={mentor.id}>
                    <div className="card-teacher mb-5 mb-lg-0">
                      <div className="image">
                        <img
                          src={`${Image_URL}/storage/${mentor.img}`}
                          alt=""
                        />
                        {/* <div className="btns">
                              <Link to={`/year/${year.id}`}>  مشاهدة المحتوي </Link>
                               </div> */}
                      </div>
                      <div className="about">
                        <h2 className="title">
                          {mentor.user.fname + " " + mentor.user.lname}
                        </h2>

                        <p> {mentor.bio}</p>
                      </div>
                      {/* <div className="analysis">
                        <p>
                          {" "}
                          عدد السنين الدراسية : <span> 3 </span>{" "}
                        </p>
                        <p>
                          {" "}
                          عدد الكورسات الشهرية: <span> 3 </span>{" "}
                        </p>
                        <p>
                          {" "}
                          عدد الحصص : <span> 3 </span>{" "}
                        </p>
                      </div> */}

                      <div className="btn">
                        <Link to={`/mentor/${mentor.id}/year/${yearId}`}> محتوي المدرس </Link>
                      </div>
                    </div>
                  </Col>
                ))
              ) : (
                <div
                  className={`non-courses mb-5 mt-5 pb-5 pt-5 ${
                    style ? "dark-mode" : ""
                  }`}
                >
                  <h2>
                    لا يتوفر مدرسين حتي الأن{" "}
                    <span className="d-block mt-1">
                      {" "}
                      سيتم اضافتهم في اقرب وقت
                    </span>
                  </h2>
                </div>
              )}
            </Row>

            <div className={`coursess ps-0 pe-0 `}>


              {/* Monthly Courses */}

              <Row className="d-flex flex-row-reverse">
                <div className="title text-center">
                  <h1>
                    {""}
                    كورسات <span>{titleSubject.name}</span>{""}
                  </h1>
                </div>
                <div className="title text-right mb-4 pb-4">
                  <h2>
                    {" "}
                    الكورسات <span> الشهرية </span>{" "}
                  </h2>
                </div>

                {coursesNotLoginMonthly && coursesNotLoginMonthly.length > 0 ? (
                  coursesNotLoginMonthly.map((course) => (
                    <Col key={course.id} lg={4} className="mb-4">
                      <div className="card mb-4">
                        <div className="image">
                          <img
                            src={`${Image_URL}/storage/${course.img}`}
                            alt=""
                            loading="lazy"
                          />
                        </div>
                        <div className="about">
                          <div className="details">
                            <h3> {course.name}</h3>
                            <p> {course.description}</p>
                          </div>
                          <div
                            className={`price ${
                              course.old_price !== "" 
                              && 
                              course.old_price !== null
                                ? "red-bg"
                                : ""
                            }`}
                          >
                            {course.price === "0.00" ? (
                              <span className="free"> هذا الكورس مجاني </span>
                            ) : (
                              <>
                                <span className="ms-1">
                                  {course.old_price === "" ||
                                  course.old_price === null ? (
                                    course.price
                                  ) : (
                                    <>
                                      <del>{course.old_price}.00</del>{" "}
                                      {course.price}
                                      <span className="ms-3">
                                        {" "}
                                        {(course.price * 100) /
                                          course.old_price}
                                        % اوفر
                                      </span>
                                    </>
                                  )}
                                </span>{" "}
                                <span> جنيهاَ </span>
                              </>
                            )}
                          </div>
                          <div className="dates mt-4">
                            <div className="date">
                              <img
                                src="/images/plus..webp"
                                alt=""
                                loading="lazy"
                              />
                              <span className="ms-2">
                                {" "}
                                {formatDate(course.created_at)}{" "}
                              </span>
                            </div>
                            <div className="date mt-2">
                              <img
                                src="/images/update..webp"
                                alt=""
                                loading="lazy"
                              />
                              <span className="ms-2">
                                {" "}
                                {formatDate(course.updated_at)}{" "}
                              </span>
                            </div>
                          </div>
                          <div className="btns">
                            <Link to="/registeruser"> اشترك الأن </Link>
                            <Link to={`/course-content/${course.id}`}>
                              {" "}
                              الدخول للكورس{" "}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))
                ) : (
                  <div
                    className={`non-courses mb-5 mt-5 pb-5 pt-5 ${
                      style ? "dark-mode" : ""
                    }`}
                  >
                    <h2>
                      لا يتوفر كورسات شهرية حتي الأن{" "}
                      <span className="d-block mt-1">
                        {" "}
                        سيتم إضافتها في اقرب وقت
                      </span>
                    </h2>
                  </div>
                )}
              </Row>

              {/* Weekly Courses */}

              <Row className="d-flex flex-row-reverse">
                <div className="title text-right mb-4 pb-4 pt-5">
                  <h2>
                    {" "}
                    الحصص <span> المنفردة </span>{" "}
                  </h2>
                </div>

                {coursesNotLoginWeekly && coursesNotLoginWeekly.length > 0 ? (
                  coursesNotLoginWeekly.map((course) => (
                    <Col key={course.id} lg={4} className="mb-4">
                      <div className="card mb-4">
                        <div className="image">
                          <img
                            src={`${Image_URL}/storage/${course.img}`}
                            alt=""
                            loading="lazy"
                          />
                        </div>
                        <div className="about">
                          <div className="details">
                            <h3> {course.name}</h3>
                            <p> {course.description}</p>
                          </div>
                          <div
                            className={`price ${
                              course.old_price !== ""  &&
                              course.old_price !== null
                                ? "red-bg"
                                : ""
                            }`}
                          >
                            {course.price === "0.00" ? (
                              <span className="free"> هذا الكورس مجاني </span>
                            ) : (
                              <>
                                <span className="ms-1">
                                  {course.old_price === "" ||
                                  course.old_price === null ? (
                                    course.price
                                  ) : (
                                    <>
                                      <del>{course.old_price}.00</del>{" "}
                                      {course.price}
                                      <span className="ms-3">
                                        {" "}
                                        {(course.price * 100) /
                                          course.old_price}
                                        % اوفر
                                      </span>
                                    </>
                                  )}
                                </span>{" "}
                                <span> جنيهاَ </span>
                              </>
                            )}
                          </div>
                          <div className="dates mt-4">
                            <div className="date">
                              <img
                                src="/images/plus..webp"
                                alt=""
                                loading="lazy"
                              />
                              <span className="ms-2">
                                {" "}
                                {formatDate(course.created_at)}{" "}
                              </span>
                            </div>
                            <div className="date mt-2">
                              <img
                                src="/images/update..webp"
                                alt=""
                                loading="lazy"
                              />
                              <span className="ms-2">
                                {" "}
                                {formatDate(course.updated_at)}{" "}
                              </span>
                            </div>
                          </div>
                          <div className="btns">
                            <Link to="/registeruser"> اشترك الأن </Link>
                            <Link to={`/course-content/${course.id}`}>
                              {" "}
                              الدخول للكورس{" "}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))
                ) : (
                  <div
                    className={`non-courses mb-5 mt-5 pb-5 pt-5 ${
                      style ? "dark-mode" : ""
                    }`}
                  >
                    <h2>
                      لا يتوفر حصص منفردة حتي الأن{" "}
                      <span className="d-block mt-1">
                        {" "}
                        سيتم إضافتها في اقرب وقت
                      </span>
                    </h2>
                  </div>
                )}
              </Row>

              
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default SubjectContent;
