import axios from "axios";
import { useCallback, useState } from "react";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { BASE_URL, Image_URL } from "../../App";
import '../../styles/card.css'
import ScrollTopButton from "../ScrollTop";
import CardNot from "../Cardnotlogin";

function Courses({loggedIn ,setLoggedIn , style}) {
  const navigate = useNavigate()

    const {id} = useParams()
    const { mentorId, yearId } = useParams(); // Add this line
    const [coursesNotLoginMonthly, setCoursesNotLoginMonthly] = useState([]);
    const [coursesNotLoginWeekly, setCoursesNotLoginWeekly] = useState([]);
    const [cards, setCards] = useState(() => []);
    const [mentorTitleName, setMentorTitleName] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
  
      const fetchData = async () => {
        try {
          const response = await fetch(
            `${BASE_URL}/api/mentor/${mentorId}/year/${yearId}`,
            {
              'Content-Type': 'application/json',
              'ngrok-skip-browser-warning': 'any',
              'Authorization': `Bearer ${token}`,
            }
          );
          const data = await response.json();
          setIsLoading(false);
          setMentorTitleName(data.data.mentor_name)
          setCoursesNotLoginMonthly(data.data.monthly_courses);
          setCoursesNotLoginWeekly(data.data.normal_courses);
          console.log(data);
        } catch (error) {
          console.error(error);
          setIsLoading(false);
        }
      };
  
      fetchData();
    }, []);

    
    // ...

    
      // الريكويست الخاص بعرض الكورسات
//     const fetchData = useCallback(async () => {
//       try {
//           const token = localStorage.getItem('token');
//           const config = {
//             headers: {
//               'Content-Type': 'application/json',
//               "ngrok-skip-browser-warning": "any",
//               'Authorization': `Bearer ${token}`,
//             }
//           };
//           const response = await axios.get(`${BASE_URL}/api/show-level-courses/${id}`, config);
//           setLevelTitle(response?.data);
//           setCards(response?.data?.data);
//           setIsLoading(false); 

//       } catch (error) {
//         setIsLoading(false); 
//       }
//   }, [id]);

//   useEffect(() => {
//     fetchData();
//     window.scrollTo(0, 0);

// }, [fetchData, id]);


// const [cardsOnline , setCardsOnline] = useState([]);
// const [cardsCenter , setCardsCenter] = useState([]);

// const fetchDataOnline = async () => {
//   const token = localStorage.getItem('token');
//   const config = {
//   headers: {
//   'Content-Type': 'application/json',
//   "ngrok-skip-browser-warning": "any",
//   'Authorization': `Bearer ${token}`,
//   }
//   };

//   try {
//   const response = await axios.get(`${BASE_URL}/api/show-level-online-courses/${id}`, config);
//   setLevelTitle(response.data);
//   setCardsOnline(response.data.data);
//   console.log(response.data.data);
//   setIsLoading(false); 
//   } catch (error) {
//   setIsLoading(false); 
//   }
//   }

//   const fetchDataCenter = async () => {
//     const token = localStorage.getItem('token');
//     const config = {
//     headers: {
//     'Content-Type': 'application/json',
//     "ngrok-skip-browser-warning": "any",
//     'Authorization': `Bearer ${token}`,
//     }
//     };

//     try {
//     const response = await axios.get(`${BASE_URL}/api/show-level-offline-courses/${id}`, config);
//     setLevelTitle(response.data);
//     setCardsCenter(response.data.data);
//     // console.log(response.data.data);
//     setIsLoading(false); 
//     } catch (error) {
//     setIsLoading(false); 
//     }
//     }

//     useEffect(() => {
//         fetchDataOnline();
//         fetchDataCenter();
//     }, []);



if (isLoading) {
  return <div className={`main-loading ${style ? 'dark-mode' : ''}`}>
    <div className="loading-course"> 
  <h2>  .... جاري تحميل الكورسات</h2>
  </div>; 
  </div>
}


function formatDate(dateString) {
  try {
    const date = new Date(dateString);
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      timeZone: "UTC",
      numberingSystem: "arab",
    };
    const formatter = new Intl.DateTimeFormat("ar-EG", options);
    const formattedDate = formatter.format(date);
    return formattedDate;
  } catch (e) {
    console.error("Error formatting date", e);
    return "";
  }
}

  function formatDate(dateString) {
    try {
      const date = new Date(dateString);
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        timeZone: "UTC",
        numberingSystem: "arab",
      };
      const formatter = new Intl.DateTimeFormat("ar-EG", options);
      const formattedDate = formatter.format(date);
      return formattedDate;
    } catch (e) {
      console.error("Error formatting date", e);
      return "";
    }
  }


    return (
      <>  

       
        <div className={`coursess ${style ? 'dark-mode' : ''}`}>
       
        {
          
          <>
           
              {/* Monthly Courses */}

              <Row className="d-flex flex-row-reverse">
                <div className="title text-center">
                  <h1>
                    {""}
                    كورسات <span> {mentorTitleName} </span>{""}
                  </h1>
                </div>
                <div className="title text-right mb-4 pb-4">
                  <h2>
                    {" "}
                    الكورسات <span> الشهرية </span>{" "}
                  </h2>
                </div>

                {coursesNotLoginMonthly && coursesNotLoginMonthly.length > 0 ? (
                  coursesNotLoginMonthly.map((course) => (
                    <Col key={course.id} lg={4} className="mb-4">
                      <div className="card mb-4">
                        <div className="image">
                          <img
                            src={`${Image_URL}/storage/${course.img}`}
                            alt=""
                            loading="lazy"
                          />
                        </div>
                        <div className="about">
                          <div className="details">
                            <h3> {course.name}</h3>
                            <p> {course.description}</p>
                          </div>
                          <div
                            className={`price ${
                              course.old_price !== "" 
                              && 
                              course.old_price !== null
                                ? "red-bg"
                                : ""
                            }`}
                          >
                            {course.price === "0.00" ? (
                              <span className="free"> هذا الكورس مجاني </span>
                            ) : (
                              <>
                                <span className="ms-1">
                                  {course.old_price === "" ||
                                  course.old_price === null ? (
                                    course.price
                                  ) : (
                                    <>
                                      <del>{course.old_price}.00</del>{" "}
                                      {course.price}
                                      <span className="ms-3">
                                        {" "}
                                        {(course.price * 100) /
                                          course.old_price}
                                        % اوفر
                                      </span>
                                    </>
                                  )}
                                </span>{" "}
                                <span> جنيهاَ </span>
                              </>
                            )}
                          </div>
                          <div className="dates mt-4">
                            <div className="date">
                              <img
                                src="/images/plus..webp"
                                alt=""
                                loading="lazy"
                              />
                              <span className="ms-2">
                                {" "}
                                {formatDate(course.created_at)}{" "}
                              </span>
                            </div>
                            <div className="date mt-2">
                              <img
                                src="/images/update..webp"
                                alt=""
                                loading="lazy"
                              />
                              <span className="ms-2">
                                {" "}
                                {formatDate(course.updated_at)}{" "}
                              </span>
                            </div>
                          </div>
                          <div className="btns">
                            <Link to="/registeruser"> اشترك الأن </Link>
                            <Link to={`/course-content/${course.id}`}>
                              {" "}
                              الدخول للكورس{" "}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))
                ) : (
                  <div
                    className={`non-courses mb-5 mt-5 pb-5 pt-5 ${
                      style ? "dark-mode" : ""
                    }`}
                  >
                    <h2>
                      لا يتوفر كورسات شهرية حتي الأن{" "}
                      <span className="d-block mt-1">
                        {" "}
                        سيتم إضافتها في اقرب وقت
                      </span>
                    </h2>
                  </div>
                )}
              </Row>

              {/* Weekly Courses */}

              <Row className="d-flex flex-row-reverse">
                <div className="title text-right mb-4 pb-4 pt-5">
                  <h2>
                    {" "}
                    الحصص <span> المنفردة </span>{" "}
                  </h2>
                </div>

                {coursesNotLoginWeekly && coursesNotLoginWeekly.length > 0 ? (
                  coursesNotLoginWeekly.map((course) => (
                    <Col key={course.id} lg={4} className="mb-4">
                      <div className="card mb-4">
                        <div className="image">
                          <img
                            src={`${Image_URL}/storage/${course.img}`}
                            alt=""
                            loading="lazy"
                          />
                        </div>
                        <div className="about">
                          <div className="details">
                            <h3> {course.name}</h3>
                            <p> {course.description}</p>
                          </div>
                          <div
                            className={`price ${
                              course.old_price !== ""  &&
                              course.old_price !== null
                                ? "red-bg"
                                : ""
                            }`}
                          >
                            {course.price === "0.00" ? (
                              <span className="free"> هذا الكورس مجاني </span>
                            ) : (
                              <>
                                <span className="ms-1">
                                  {course.old_price === "" ||
                                  course.old_price === null ? (
                                    course.price
                                  ) : (
                                    <>
                                      <del>{course.old_price}.00</del>{" "}
                                      {course.price}
                                      <span className="ms-3">
                                        {" "}
                                        {(course.price * 100) /
                                          course.old_price}
                                        % اوفر
                                      </span>
                                    </>
                                  )}
                                </span>{" "}
                                <span> جنيهاَ </span>
                              </>
                            )}
                          </div>
                          <div className="dates mt-4">
                            <div className="date">
                              <img
                                src="/images/plus..webp"
                                alt=""
                                loading="lazy"
                              />
                              <span className="ms-2">
                                {" "}
                                {formatDate(course.created_at)}{" "}
                              </span>
                            </div>
                            <div className="date mt-2">
                              <img
                                src="/images/update..webp"
                                alt=""
                                loading="lazy"
                              />
                              <span className="ms-2">
                                {" "}
                                {formatDate(course.updated_at)}{" "}
                              </span>
                            </div>
                          </div>
                          <div className="btns">
                            <Link to="/registeruser"> اشترك الأن </Link>
                            <Link to={`/course-content/${course.id}`}>
                              {" "}
                              الدخول للكورس{" "}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))
                ) : (
                  <div
                    className={`non-courses mb-5 mt-5 pb-5 pt-5 ${
                      style ? "dark-mode" : ""
                    }`}
                  >
                    <h2>
                      لا يتوفر حصص منفردة حتي الأن{" "}
                      <span className="d-block mt-1">
                        {" "}
                        سيتم إضافتها في اقرب وقت
                      </span>
                    </h2>
                  </div>
                )}
              </Row>
         
          </>
      
        }

          </div>
                  < ScrollTopButton />
      </>
    )
}
export default Courses;