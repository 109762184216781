import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals.js";
import "bootstrap/dist/css/bootstrap.min.css";

import { BrowserRouter, HashRouter } from "react-router-dom";


// import { AuthProvider } from './context/AuthProvider';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <Provider store={store}>
  <React.StrictMode>
  <HashRouter>
      {/* <AuthProvider> */}
      {/* <BrowserRouter>  */}
        <App />
      {/* </BrowserRouter> */}
    
      {/* </AuthProvider> */}
  </HashRouter>
 </React.StrictMode>
  // </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
