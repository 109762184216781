  import { Link, useParams } from "react-router-dom";
  import '../styles/cardyears.css'
  import { BASE_URL, Image_URL } from "../App";
  import { useEffect, useState } from "react";
  import axios from "axios";





  function CardTeacher ({level ,  mentorId, yearId , loggedIn}) {

    const {id} = useParams()


    

      return (
          <>
                    <div className="card-year mb-5 mb-lg-0">
                      <div className="image">
                      <img src={`${Image_URL}/storage/${level.img}`} alt=""/>
                      <div className="btns">
                      {loggedIn ? (
            <Link to={`/auth-courses/mentor/${mentorId}/year/${yearId}`}>
              مشاهدة المحتوى
            </Link>
          ) : (
            <>
              <Link to="/registeruser"> اشترك الأن </Link>
              <Link to={`/mentor/${mentorId}/year/${yearId}`}>
                مشاهدة المحتوى
              </Link>
            </>
          )}
                          </div>
                      </div>
                      
                      
                  </div>
          </>
      )
  }

  export default CardTeacher;