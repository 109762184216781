

import axios from 'axios';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import { BASE_URL } from '../App';
import '../styles/registeruser.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';


const initialState = {
    email: "",
    password: "",
    fname1: "",
    fname2: "",
    lname1: "",
    lname2: "",
    pphone: "",
    phone: "",
    level: "",
    government: "",
    national_id: "",
    center_code: "",
    national_number: "",
    // type: "",
    password_confirmation: "",
  };

function Registeruser({style}) {


    
    const [formData, setFormData] = useState(initialState);
    const [errors, setErrors] = useState("");
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [years, setYears] = useState([]);

    const handleInputChange = (event) => {
        const {name , value , files , type} = event.target;


        if (type === 'file') {
          setFormData((prevData) => ({
            ...prevData,
            [name]: files[0], // Store the file object in formData
          }));
        } else {
          setFormData((prevData) => ({ ...prevData, [name]: value }));
        }

    };
            // ريكويست الخاص بإنشاء الحساب

      const handleRegister = async (event) => {
        event.preventDefault();
        setLoading(true); // Set loading state to true

        try {
          const formDataObj = new FormData();
          Object.keys(formData).forEach((key) => {
            formDataObj.append(key, formData[key]);
          });
          const response = await axios.post(`${BASE_URL}/api/register`, formDataObj);
          const userId = response.data.data.user.id; 

          setFormData(initialState)
            swal({
              title: "تم تسجيل حسابك بنجاح!",
              text: `ID الخاص بك ${userId}`,
              icon: "success",
              button: "حسناّ!",
              
              }).then(() => {
                navigate("/loginuser");
              });

        } catch(e) {
          setLoading(false); // Reset loading state to false

            if(e.response.status === 422) {
                setErrors(e.response.data.errors);
            }
            swal({
                title: " !راجع بياناتك تاني  ",
                icon: "warning",
                button: "حسناّ!",
              })
        }
    }

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
  


      // ريكويست الخاص بصفحة Years
      const fetchYears = async () => {
        try {
          const response = await fetch(`${BASE_URL}/api/register-years`, {
            method: "GET",
            headers: config.headers,
          });
          const data = await response.json();
          setYears(data.data);
        } catch (error) {
          console.error(error);  
        }
      };
  
      fetchYears();
    }, []);






    return (
        <>
        <div className={`register-user ${style ? 'dark-mode' : ''}`}>
    
            <Container>  
                <Row>
                    {/* <Col lg={12}> */}
                        <div className="box mt-2">
                          <Col lg={6}>
                          <Form onSubmit={handleRegister}>
                          <h2 className="mb-5">انشئ <span>حساب</span></h2>

                    <div className="input-group">
                    <Form.Group className="big-input mb-3" controlId="formBasicText" >
        <Form.Label>الأسم الأول</Form.Label>
        <Form.Control type="text" placeholder="ادخل الأسم الأول" value={formData.fname1} name="fname1" onChange={handleInputChange}/>
        <Form.Text className="text-muted">
        {errors.fname1 && (
                                <div  className="error">{errors.fname1[0]}</div>
                            )}
        </Form.Text>
      </Form.Group>
                    <Form.Group className="mb-3 big-input" controlId="formBasicText">
        <Form.Label>الأسم الثاني</Form.Label>
        <Form.Control type="text" placeholder="ادخل الأسم الثاني" value={formData.fname2}   name="fname2" onChange={handleInputChange}/>
        <Form.Text className="text-muted">
        {errors.fname2 && (
                                <div className="error">{errors.fname2[0]}</div>
                            )}
        </Form.Text>
      </Form.Group>
    
  
                    </div>
                    <div className="input-group">
                    <Form.Group className="big-input mb-3" controlId="formBasicText" >
        <Form.Label>الأسم الثالث</Form.Label>
        <Form.Control type="text" placeholder="ادخل الأسم الثالث" value={formData.lname1} name="lname1" onChange={handleInputChange}/>
        <Form.Text className="text-muted">
        {errors.lname1 && (
                                <div  className="error">{errors.lname1[0]}</div>
                            )}
        </Form.Text>
      </Form.Group>
                    <Form.Group className="mb-3 big-input" controlId="formBasicText">
        <Form.Label>الأسم الرابع</Form.Label>
        <Form.Control type="text" placeholder="ادخل الأسم الرابع" value={formData.lname2}   name="lname2" onChange={handleInputChange}/>
        <Form.Text className="text-muted">
        {errors.lname2 && (
                                <div className="error">{errors.lname2[0]}</div>
                            )}
        </Form.Text>
      </Form.Group>
    
  
                    </div>

                    <div className="input-group">
                    <Form.Group className="mb-3 big-input" controlId="formBasicPhone" >
        <Form.Label>رقم تليفون الطالب</Form.Label>
        <Form.Control type="number" placeholder="ادخل  رقم تليفون الطالب" value={formData.phone} name="phone" onChange={handleInputChange}/>
        <Form.Text className="text-muted">
        {errors.phone && (
                                <div  className="error">{errors.phone[0]}</div>
                            )}
        </Form.Text>
      </Form.Group>
                    <Form.Group className="mb-3 big-input" controlId="formBasicPhone" >
        <Form.Label> رقم تليفون ولي الأمر (واتساب) </Form.Label>
        <Form.Control type="number" placeholder="ادخل  رقم تليفون ولي الأمر" value={formData.pphone} name="pphone" onChange={handleInputChange}/>
        <Form.Text className="text-muted">
        {errors.pphone && (
                                <div className="error">{errors.pphone[0]}</div>
                            )}
        </Form.Text>
      </Form.Group>
                    </div>


                    <Form.Group className="mb-3 w-100" controlId="formBasicPassword">
        <Form.Label> البريد الألكتروني للطالب</Form.Label>
        <Form.Control type="email"    placeholder="ادخل البريد الألكتروني" value={formData.email} name="email" onChange={handleInputChange}/>
        <Form.Text className="text-muted">
        {errors.email && (
                                <div  className="error">{errors.email[0]}</div>
                            )}
        </Form.Text>
      </Form.Group>
                    <Form.Group className="mb-3 w-100" controlId="formBasicPassword">
        <Form.Label>  المدرسة </Form.Label>
        <Form.Control type="text"    placeholder="ادخل مدرستك" value={formData.school} name="school" onChange={handleInputChange}/>
        <Form.Text className="text-muted">
        {errors.school && (
                                <div  className="error">{errors.school[0]}</div>
                            )}
        </Form.Text> 
      </Form.Group>

      <Form.Group className="mb-3 w-100" controlId="formBasicPassword">
          <Form.Label>   الرقم القومي   </Form.Label>
          <Form.Control type="number"  placeholder="ادخل  الرقم القومي" value={formData.national_id} name="national_id" onChange={handleInputChange}/>
          <Form.Text className="text-muted">
          {errors.national_id && (
                                  <div  className="error">{errors.national_id[0]}</div>
                              )}
          </Form.Text>
        </Form.Group>



                  
      <Form.Group className="big-input mb-3" controlId="formBasicEmail">
  <Form.Label>برجاء اختيار السنة الدراسية</Form.Label>
  <Form.Select
    aria-label="Default select example"
    value={formData.level}
    name="level"
    onChange={handleInputChange}
  >
    <option value=""> اختار السنه الدراسية</option>
    {years.map((year) => (
      <option key={year.id} value={year.id}>
        {year.name}
      </option>
    ))}
  </Form.Select>
  <Form.Text className="text-muted">
    {errors.level && <div className="error">{errors.level[0]}</div>}
  </Form.Text>
</Form.Group>







            
      {/* <Form.Group className="big-input mb-3" controlId="formBasicEmail">
                    <Form.Label>برجاء اختيار نظام الدراسة</Form.Label>
                    <Form.Select aria-label="Default select example"         value={formData.type} name="type" onChange={handleInputChange}>
                    <option >اختر نظام الدراسة</option>

                          <option value="1">  أونلاين</option>
                          <option  value="0"> سنتر</option>
    </Form.Select>
    <Form.Text className="text-muted">
        {errors.type && (
                                <div  className="error">{errors.type[0]}</div>
                            )}
        </Form.Text>
      </Form.Group> */}

      
                    <Form.Group className="mb-3 big-input" controlId="formBasicPassword">
                    <Form.Label>برجاء اختيار المحافظة</Form.Label>
                    <Form.Select aria-label="Default select example" value={formData.government} name="government" onChange={handleInputChange}>

      <option >اختر المحافظة</option>
<option value="القاهرة">القاهرة</option>
<option value="الجيزة">الجيزة</option>
<option value="الإسكندرية">الإسكندرية</option>
<option value="الإسماعيلية">الإسماعيلية</option>
<option value="كفر الشيخ">كفر الشيخ</option>
<option value="أسوان">أسوان</option>
<option value="أسيوط">أسيوط</option>
<option value="الأقصر">الأقصر</option>
<option value="الوادي الجديد">الوادي الجديد</option>
<option value ="شمال سيناء">شمال سيناء</option>
<option value="البحيرة">البحيرة</option>
<option value="بني سويف">بني سويف</option>
<option value="بورسعيد">بورسعيد</option>
<option value="البحر الأحمر">البحر الأحمر</option>
<option value="الدقهلية">الدقهلية</option>
<option value="جنوب سيناء">جنوب سيناء</option>
<option value="دمياط">دمياط</option>
<option value="سوهاج">سوهاج</option>
<option value="السويس">السويس</option>
<option value="الشرقية">الشرقية</option>
<option value="الغربية">الغربية</option>
<option value="الفيوم">الفيوم</option>
<option value="القليوبية">القليوبية</option>
<option value="قنا">قنا</option>
<option value="مطروح">مطروح</option>
<option value="المنوفية">المنوفية</option>
<option value="المنيا">المنيا</option>
    </Form.Select>
    <Form.Text className="text-muted">
        {errors.government && (
                                <div  className="error">{errors.government[0]}</div>
                            )}
        </Form.Text>
      </Form.Group>

                    {/* <Form.Group className="mb-3 w-100" controlId="formBasicPassword">
        <Form.Label>  كود السنتر (لطلاب السنتر فقط)*</Form.Label>
        <Form.Control type="number"    placeholder="ادخل  كود السنتر" value={formData.center_code} name="center_code" onChange={handleInputChange}/>
        <Form.Text className="text-muted">
        {errors.center_code && (
                                <div  className="error">{errors.center_code[0]}</div>
                            )}
        </Form.Text>
      </Form.Group> */}


      



  

                    
                    
                    <div className="input-group">
                    <Form.Group className="big-input mb-3" controlId="formBasicEmail">
<Form.Label>كلمة المرور</Form.Label>
<div className="password-input">
  <Form.Control
    type={showPassword ? 'text' : 'password'} // Toggle password visibility
    placeholder="ادخل  كلمة المرور"
    value={formData.password}
    name="password"
    onChange={handleInputChange}
  />
  <span className="password-toggle" onClick={() => setShowPassword(!showPassword)}>
    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
  </span>
</div>
<Form.Text className="text-muted">
  {errors.password && <div className="error">{errors.password[0]}</div>}
</Form.Text>
</Form.Group>

<Form.Group className="mb-3 big-input" controlId="formBasicPassword">
<Form.Label>تأكيد كلمة المرور</Form.Label>
<Form.Control
  type={showPassword ? 'text' : 'password'} // Toggle password visibility
  placeholder="تأكيد كلمة المرور"
  value={formData.password_confirmation}
  name="password_confirmation"
  onChange={handleInputChange}
/>
<span className="password-toggle" onClick={() => setShowPassword(!showPassword)}>
  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
</span>
<Form.Text className="text-muted">
  {errors.password_confirmation && <div className="error">{errors.password_confirmation[0]}</div>}
</Form.Text>
</Form.Group>

    
                    </div>
    
                    <Button
  variant="outline-primary"
  className="mt-3 mb-3"
  type="submit"
  disabled={loading} // Disable the button during loading
>
  {loading ? (
      <>
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          {' '}جاري انشاء الحساب...
      </>
  ) : (
      'انشئ الحساب'
  )}
</Button>{' '}

                                </Form>
                          </Col>
                          <Col lg={6}>
                          <div className="welcome-sign">
                    <div className="content text-center">
                      <h2> , اهلا بك في  <span className="d-block mt-2">منصة  ألفا  </span> </h2>
                      <p className="mt-4">لو معندكش ايميل اعمل ايميل لو عندك سجل خلاص  </p>
                      <Link className="mt-4 d-block"  to="/loginuser"> ! لدي حساب بالفعل</Link>
                    </div>
                  </div>
                          </Col>
                    

                        </div>
                    {/* </Col> */}
                </Row>
            </Container>
        </div>
        </>
    )
    
}

export default Registeruser;