
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { BASE_URL, Image_URL } from '../../App';
import ScrollTopButton from '../ScrollTop';
import SidebarProfile from '../SidebarProfile';
import '../../styles/profile.css' 

import Card from '../Cardlogin';
import '../../styles/card.css'
import CardProfile from '../CardProfile';
import CardTeacherProfile from '../CardTeacherProfile';

function ProfileTeachers ({style , auth}) {

      const [cards , setCards] = useState([]);
      const [isLoading, setIsLoading] = useState(true);

    
    useEffect(() => {
      
        window.scrollTo(0, 0);
 
              const token = localStorage.getItem('token');
              const config = {
                headers: {
                  'Content-Type': 'application/json',
                  "ngrok-skip-browser-warning": "any",
                  'Authorization': `Bearer ${token}`,
                }
              };       
              axios.get(`${BASE_URL}/api/enrolled-mentors` , config)
                .then(response => {
                  setCards(response.data);
                  setIsLoading(false)
                  
                })
                .catch(error => {
                  // console.error(error);
                  setIsLoading(false)
                });
            }, [])

            function formatDate(dateString) {
              try {
                const date = new Date(dateString);
                const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC', numberingSystem: 'arab'};
                const formatter = new Intl.DateTimeFormat('ar-EG', options);
                const formattedDate = formatter.format(date);
                return formattedDate;
              } catch (e) {
                // console.error('Error formatting date', e);
                return '';
              }
            }

            

            

    return (
      <>
      {
           isLoading
           ?
           <div className={`main-loading ${style ? 'dark-mode' : ''}`}>
           <div className="loading-course"> 
          <h2>  .... جاري تحميل  المدرسين</h2>
          </div>; 
          </div>
          :
          <div className={`profile d-flex ${style ? 'dark-mode' : ''}`}>
                <SidebarProfile />
                
                  <div className={`content-profile  ${style ? 'dark-mode' : ''}`}>
                  <div className='coursess p-0'>
                     <Row className="mb-4">
                       <Col lg={12}>
                         <div className="title-main">
                           <h2>  مدرسينك علي  <span>  المنصة </span> </h2>
                         </div>
                       </Col>
                     </Row>
                     <Row className="mb-4">
                  {

                    cards.length > 0
                    ?
                    cards.map((mentor) => (
                      <Col lg={4} key={mentor.id}>
                        <CardTeacherProfile auth={auth} mentor={mentor} />
                      </Col>
                    ))
                    :
                    <div className="non-courses mb-5  mt-5 ">
                    <h2>  مفيش مدرسين مشترك معاهم  لحد دلوقتي  <span className="d-block mt-1">     علي المنصة  </span> </h2>
                  </div>
                  
                  }
                       
                     </Row>
                     
                     
                   </div>
                   </div>
                  </div>
         
      }
 
          
         < ScrollTopButton />
 
     </>
    )
}

export default ProfileTeachers;