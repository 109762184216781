


import { Link } from "react-router-dom";
import '../styles/card.css'
import { Image_URL } from "../App";



function CardProfile({cardcourse}) {

  function formatDate(dateString) {
    try {
      const date = new Date(dateString);
      const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC', numberingSystem: 'arab'};
      const formatter = new Intl.DateTimeFormat('ar-EG', options);
      const formattedDate = formatter.format(date);
      return formattedDate;
    } catch (e) {
      // console.error('Error formatting date', e);
      return '';
    }
  }
   return (
    <>
     <div className="card mb-4">
    <div className="image">
    <img src={`${Image_URL}/storage/${cardcourse.attributes.img}`} alt="" loading="lazy"/>
    </div>
    <div className="about">
          <div className="details">
          <h3> {cardcourse.attributes.name}</h3>
           <p> {cardcourse.attributes.description}</p>
          </div>
      <div className="price">

       
          <span className="free">تم شراء هذا الكورس</span>
         
      

      
      
    </div>
    <div className="dates mt-4">
      <div className="date">
        <img src="/images/plus..webp" alt="" loading="lazy"/>
        <span className="ms-2">  {formatDate(cardcourse.attributes.created_at)}      </span>
      </div>
      <div className="date mt-2">
        <img src="/images/update..webp" alt="" loading="lazy"/>
        <span className="ms-2">  {formatDate(cardcourse.attributes.updated_at)}      </span>
      </div>
    </div>
    <div className="btns">
     
      <Link className="cardbtn-profile" to={`/contentcourse/${cardcourse.id}`}> الدخول للكورس </Link>
    </div>
 
    </div>
  
  </div>
    </>
   )
}

export default CardProfile;